export default function HomeScreen() {

    return (
        <div className="App">
            <h1 className="mt-3">
                Bienvenue dans notre établissment
            </h1>

            <img src="./logos/logo.png" className="m-auto" style={{ width: '50%' }} />

            <p style={{ width: '80%' }} className="m-auto">
                Choisissez ici votre menu, et payez soit à la caisse, soit par QR Code à l'aide de votre téléphone. Nous viendrons vous servir !
            </p>

            <button onClick={() => { window.location.href = '/form' }} className="mt-5" style={{ border: 'none', padding: '1em', borderRadius: '1em', fontWeight: 'bold' }}>
                COMMENCER MA COMMANDE
            </button>

            <div className="container mt-5">
                <div className="row">
                    <div className="col col-1">

                    </div>
                    <div className="col col-5">
                        <img src="./logos/fr.png" style={{ width: '100%', height: '100px' }} />
                    </div>
                    <div className="col col-5">
                        <img src="./logos/en.png" style={{ width: '100%', height: '100px' }} />
                    </div>
                    <div className="col col-1">

                    </div>
                </div>
            </div>
        </div>
    );
}