import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './components/Home/HomeScreen';
import Form from './components/Form/Form';
import Catalogue from './components/Catalogue/Catalogue';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/form' element={<Form />} />
        <Route path='/catalogue' element={<Catalogue />} />
      </Routes>
    </Router>
  );
}

export default App;
