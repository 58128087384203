import { useEffect, useState } from "react";
import { getItemsByProjectId } from "../../request/projects";
import { project_id } from "../../request/config";
import { Button, Offcanvas } from 'react-bootstrap';
import { i18nData } from '../../utils/i18n';
import { getCookie } from "../../utils/storage";
import { useLocation } from "react-router-dom";
import { addItemToCommand, createPaiementLink, removeItemToCommand } from "../../request/commands";

export default function Catalogue() {

    const [project, setProject] = useState(null);
    const [currentCategory, setCurrentCategory] = useState('');
    const [currentProduct, setCurrentProduct] = useState(null);
    const [show, setShow] = useState(false);
    const [command, setCommand] = useState({ items: [] });
    const command_id = getCookie('command_id');

    const location = useLocation();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const loadProject = async () => {
        const res = await getItemsByProjectId(project_id);
        if (res.success) {
            setProject(res.data);
        }
    }

    useEffect(() => {
        loadProject();
    }, []);

    useEffect(() => { }, [currentCategory]);

    if (!project) {
        return (
            <div className="App">
                <h1 className="container">
                    <div className="row">
                        <div>
                            <h1 className="text-center">
                                Chargement...
                            </h1>
                        </div>
                    </div>
                </h1>
            </div>
        );
    }

    return (
        <div className="App">
            <Button style={{ background: '#FFFFFF', border: ' none', color: 'black', fontWeight: 'bold' }} className="mt-3" variant="primary" onClick={handleShow}>
                Filtrer les catégories
            </Button>

            <Offcanvas show={show} onHide={handleClose} style={{ color: 'white', background: 'rgba(0, 0, 0, 0.6)' }}>
                <Offcanvas.Header closeButton style={{ color: 'white' }}>
                    <Offcanvas.Title>Catégories</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul>
                        <li style={{ fontWeight: (currentCategory === '') ? 'bold' : 'normal' }} onClick={() => { setCurrentCategory('') }}>
                            Toute les catégories
                        </li>
                        {
                            project.categories.map((category) => {
                                return (
                                    <li key={category.id} style={{ fontWeight: (currentCategory === category.id) ? 'bold' : 'normal' }} onClick={() => { setCurrentCategory(category.id) }}>
                                        {i18nData(category, 'category_name', 'fr')}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>

            <div className="container mt-5" style={{ marginBottom: '12vh' }}>
                <div className="row">
                    {
                        project.items.map((item) => {
                            if (!verifyCategoryExist(item.categories, currentCategory)) {
                                return null;
                            }
                            return (
                                <div key={item.id} className="col col-6 mt-3 mb-3">
                                    <div style={{ boxShadow: "10px 10px 10px rgba(0,0,0,0.36)", borderRadius: '0.4em', padding: '0.1em', background: '#FFFFFF', height: '100%', minHeight: '200px' }} onClick={() => { setCurrentProduct(item) }}>
                                        {
                                            item.images.length ?
                                                <img style={{ borderRadius: '1em', resize: 'block', width: '100%' }} src={item.images[0].uri} alt="" />
                                                :
                                                <img style={{ borderRadius: '1em', resize: 'block', width: '100%' }} src="https://cdn-icons-png.flaticon.com/512/5845/5845342.png" />
                                        }
                                        <h5 style={{ color: 'black' }}>
                                            {i18nData(item, 'label', 'fr')}
                                        </h5>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <Modal setCommand={setCommand} command={command} product={currentProduct} setProduct={setCurrentProduct} command_id={command_id} />
            <Footer command={command} command_id={command_id} />
        </div>
    );
}

function Footer({ command, command_id }) {

    const [detailShow, setDetailShow] = useState(false);

    const toPaiement = async () => {
        if (!verifyCommandedItems(command.items)) {
            return;
        }

        const res = await createPaiementLink(command_id);

        if (res.success) {
            console.log({ res });
            window.location.href = res.data.url;
        }
    }

    if (detailShow) {
        return (
            <div style={{ color: 'black', background: '#C4C4C4', position: 'fixed', width: '100%', height: '100vh', bottom: 0, zIndex: 500 }}>

                <div className="container" style={{ overflowY: 'scroll', padding: '0.4em', overflowX: 'hidden' }}>
                    <div className="row" style={{ fontWeight: 'bold' }}>
                        <div className="col col-5">
                            Nom du produit
                        </div>
                        <div className="col col-3">
                            Qts
                        </div>
                        <div className="col col-2">
                            Prix
                        </div>
                        <div className="col col-2">
                            Sous total
                        </div>
                        <hr />
                    </div>
                    {
                        Array.from(new Map(command.items.map(item => [item.id, item])).values()).map((item) => {
                            if (!item.qts_commanded) return <></>;
                            return (
                                <>
                                    <div className="row" key={item.id}>
                                        <div className="col col-5">
                                            {i18nData(item, 'label', 'fr')}
                                        </div>
                                        <div className="col col-3">
                                            {item.qts_commanded}
                                        </div>
                                        <div className="col col-2">
                                            {item.price.toFixed(2) + '€'}
                                        </div>
                                        <div className="col col-2">
                                            {(item.price * item.qts_commanded).toFixed(2) + '€'}
                                        </div>
                                        <hr />
                                    </div>
                                </>
                            )
                        })
                    }
                </div>

                <div className="container" style={{ position: 'absolute', bottom: '3vh' }}>
                    <div className="row">
                        <div className="col col-6">
                            <button onClick={() => { setDetailShow(false) }} className="mt-3" style={{ color: 'white', fontWeight: 'bold', borderRadius: '0.4em', padding: '0.2em', background: 'orange', border: 'none', width: '100%', textAlign: 'center' }}>
                                Masquer le détail
                            </button>
                        </div>
                        <div className="col col-6">
                            <button onClick={toPaiement} className="mt-3" style={{ color: 'white', fontWeight: 'bold', borderRadius: '0.4em', padding: '0.2em', background: 'green', border: 'none', width: '100%', textAlign: 'center' }}>
                                Payer
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    return (
        <div style={{ background: '#C4C4C4', position: 'fixed', width: '100%', height: '10vh', bottom: 0, zIndex: 500 }}>
            <div className="container">
                <div className="row">
                    <div className="col col-6">
                        <button onClick={() => { setDetailShow(true) }} className="mt-3" style={{ color: 'white', fontWeight: 'bold', borderRadius: '0.4em', padding: '0.2em', background: 'orange', border: 'none', width: '100%', textAlign: 'center' }}>
                            Voir le détail
                        </button>
                    </div>
                    <div className="col col-6">
                        <button onClick={toPaiement} className="mt-3" style={{ color: 'white', fontWeight: 'bold', borderRadius: '0.4em', padding: '0.2em', background: 'green', border: 'none', width: '100%', textAlign: 'center' }}>
                            Payer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function getItemQts(item_id, items) {

    let rep = 0;

    items.map((item) => {
        (item.id) === item_id ? rep = item.qts_commanded : <></>;
    });

    return rep;
}

function verifyCommandedItems(commanatedItems) {

    let response = false;

    Array.from(new Map(commanatedItems.map(item => [item.id, item])).values()).map((item) => {
        if (item.qts_commanded) {
            response = true;
        }
    });

    return response;
}

function Modal({ product, setProduct, command_id, setCommand, command }) {

    const [loading, setLoading] = useState(false);

    if (!product) {
        return null;
    }

    return (
        <div className="popup-overlay" onClick={() => { setProduct(null) }}>
            <div className="popup-content" style={{ color: 'black' }} onClick={(e) => { e.stopPropagation(); }}>
                <img style={{ width: '100%', height: 'auto', borderRadius: '1em' }} src={(product.images.length) ? product.images[0].uri : 'https://cdn-icons-png.flaticon.com/512/5845/5845342.png'} alt="" />
                <h2>{i18nData(product, 'label', 'fr')}</h2>
                <p>{i18nData(product.description, 'text', 'fr')}</p>
                {
                    product.description.description_fields.map((description_field) => {
                        switch (description_field.type) {
                            case 'title':
                                return (
                                    <h3 key={description_field.id}>
                                        {i18nData(description_field, 'content', 'fr')}
                                    </h3>
                                );
                            case 'paragraph':
                                return (
                                    <p key={description_field.id}>
                                        {i18nData(description_field, 'content', 'fr')}
                                    </p>
                                );
                            default:
                                return null;
                        }
                    })
                }
                <div className="container">
                    <div className="row">
                        <div className="col col-3">
                            <button disabled={loading} onClick={async () => { setLoading(true); const res = await removeItemToCommand(command_id, product.id); console.log({ res }); res.success ? setCommand({ ...command, items: [...command.items, { ...product, qts_commanded: res.data.qts }] }) : <></>; setLoading(false) }} style={{ margin: 'auto', fontSize: '30px', background: 'red', height: '50px', width: '50px', borderRadius: '50px', border: 'none', color: "white" }}>
                                -
                            </button>
                        </div>
                        <div className="col col-6">
                            {getItemQts(product.id, command.items) + '(qts)'}
                        </div>
                        <div className="col col-3">
                            <button disabled={loading} onClick={async () => { setLoading(true); const res = await addItemToCommand(command_id, product.id); console.log({ res }); res.success ? setCommand({ ...command, items: [...command.items, { ...product, qts_commanded: res.data.qts }] }) : <></>; setLoading(false) }} style={{ margin: 'auto', fontSize: '30px', background: 'green', height: '50px', width: '50px', borderRadius: '50px', border: 'none', color: "white" }}>
                                +
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


function verifyCategoryExist(catArray, catId) {
    if (!catId) return true;

    return catArray.some((cat) => {
        return cat.id === catId;
    });
}