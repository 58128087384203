import { useState } from "react";
import { createCommand } from "../../request/commands";
import { setCookie } from "../../utils/storage";

export default function Form() {

    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');

    const next = async () => {
        const res = await createCommand(prenom, nom);

        if (res.success) {
            setCookie('first_name', res.data.client.first_name);
            setCookie('last_name', res.data.client.last_name);
            setCookie('command_id', res.data.client.command_id);

            window.location.href = '/catalogue';
        }
    }

    return (
        <div className="App mt-3">
            <h1 className="mt-3">
                Bienvenue dans notre établissment
            </h1>
            <img src="./logos/logo.png" style={{ width: '50%' }} />

            <h2 className="mt-3">
                Entrez vos informations
            </h2>

            <div className="container mt-5">
                <div className="row">
                    <div className="col col-6">
                        <input placeholder="Nom" onChange={(e) => { setNom(e.target.value) }} value={nom} type="text" className="form-control" />
                    </div>
                    <div className="col col-6">
                        <input placeholder="Prénom" onChange={(e) => { setPrenom(e.target.value) }} value={prenom} type="text" className="form-control" />
                    </div>
                </div>

                <button className="mt-3" onClick={next} style={{ fontWeight: 'bold', border: 'none', fontSize: '1.4em', borderRadius: '0.6em', padding: '0.4em' }}>
                    Commencer
                </button>
            </div>
        </div>
    );
}