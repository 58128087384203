import Cookies from 'js-cookie';

export const setCookie = (key, val) => {
    Cookies.set(key, val, { expires: 1 });
    console.log('Cookie set');
};

export const getCookie = (key) => {
    const value = Cookies.get(key);
    if (value) {
        return value;
    }
};

export const deleteCookie = (key) => {
    Cookies.remove(key);
    console.log('Cookie removed');
};