import requestApi from "./request_api.js";
import { key, project_id } from "./config.js";

export async function updateCommandStatus(command_id, status) {
    const res = await requestApi('/updateCommandStatus', 'post', {
        token: key,
        input: {
            id: command_id,
            status: status,
        }
    });

    /**
     * TO DO!
     */
    return res.data;
}

export async function createCommand(first_name, last_name) {
    const res = await requestApi('/createCommand', 'post', {
        token: key,
        input: {
            project_id: project_id,
            first_name: first_name,
            last_name: last_name,
        }
    });

    /**
     * TO DO!
     */
    return res.data;
}

export async function createPaiementLink(command_id) {
    const res = await requestApi('/createPaiementLink', 'post', {
        token: key,
        input: {
            command_id: command_id,
        }
    });

    /**
    * TO DO!
    */
    return res.data;
}

export async function addItemToCommand(command_id, item_id) {
    const res = await requestApi('/addItemToCommand', 'post', {
        token: key,
        input: {
            command_id,
            item_id,
        }
    });

    /**
     * TO DO!
     */
    return res.data;
}

export async function removeItemToCommand(command_id, item_id){
    const res = await requestApi('/removeItemCommand', 'post', {
        command_id,
        item_id,
    });

    return res.data;
}

export async function getCommandById(command_id){
    const res = await requestApi('/getCommandById', 'post',{
        token: key,
        command_id,
    });

    return res.data;
}